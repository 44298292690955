import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

@Component({
    selector: 'pgis-validation-error',
    template: `<ng-template #errorTip
                     let-control>
                <ng-container *ngIf="control.hasError(fieldName)">
                    {{ control.getError(fieldName) }}
                </ng-container>
                <ng-container *ngIf="control.hasError('required')">
                    Ievadlauks ir obligāts
                </ng-container>
                <ng-container *ngIf="control.hasError('pattern')">
                    Nepareizs formāts
                </ng-container>
                <ng-container *ngIf="control.hasError('min')">
                    Vērtībai jābūt lielākai par {{ control.errors.min.min }}
                </ng-container>
                <ng-container *ngIf="control.hasError('max')">
                    Vērtībai jābūt lielākai par {{ control.errors.max.max }}
                </ng-container>
            </ng-template>`
})
export class ValidationErrorComponent {

    @ViewChild('errorTip', { static: true })
    tRef: TemplateRef<any>;

    @Input()
    fieldName: string;

    getTemplateRef(): TemplateRef<any> {
        return this.tRef;
    }
}
