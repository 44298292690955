import { Component } from "@angular/core";

import { AuthService } from "@pgis/core/services";
import { ProfileFilesService } from "@pgis/core/services/profile-files.service";
import { SelectService } from "./select.service";

import { Feature } from "@pgis/shared/models/feature.model";
import { GeomStyle } from "@pgis/shared/models/classifiers.model";
import { SelectedFeature } from "../selected-feature.model";

import VectorSource from "ol/source/Vector";
import GeoJSON from 'ol/format/GeoJSON';
import olFeature from 'ol/Feature';
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Icon from "ol/style/Icon";
import FontSymbol from 'ol-ext/style/FontSymbol';
import FillPattern from 'ol-ext/style/FillPattern';
import StrokePattern from 'ol-ext/style/StrokePattern';
import { Point } from "ol/geom";
import Text from 'ol/style/Text';
import VectorLayer from "ol/layer/Vector";
import CircleStyle from "ol/style/Circle";

@Component({
    template: ''
})
export abstract class LayerSource {
    
    singleLayerToLoad: number | null;
    visibleClassifiers: number[] = [];

    currentCompanyId: number;
    protected customIcons: { url: string, base64Img: string | ArrayBuffer }[] = [];

    abstract source: VectorSource;
    abstract loaded: number[];
    abstract type: string;

    abstract loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void>;
    abstract getLayer(): VectorLayer<VectorSource>;

    selectedFeature: olFeature;
    selectedFeatures: SelectedFeature[];

    constructor(private profileFilesService: ProfileFilesService,
        private authService: AuthService,
        private selectService: SelectService) {
        this.currentCompanyId = this.authService.decodedToken.company_id;
        this.customIcons = localStorage['customIcons'] ? JSON.parse(localStorage['customIcons']) : [];
        if (this.currentCompanyId && localStorage['customIcons_' + this.currentCompanyId]) {
            this.customIcons.push(...JSON.parse(localStorage['customIcons_' + this.currentCompanyId]));
        }
        else {
            localStorage.setItem('customIcons_' + this.currentCompanyId, JSON.stringify(this.customIcons));
        }
    }

    initSource() {
        this.loaded = [];
        this.source = new VectorSource({
            format: new GeoJSON(),
            loader: (extent, resolution, projection) => {
                const zeroZoomResolution = 156543.03390625;
                const zoom = Math.round((Math.log(zeroZoomResolution) * Math.LOG2E - Math.log(resolution) * Math.LOG2E));
                let classifiersToLoad = this.visibleClassifiers;
                if(this.singleLayerToLoad) {
                    classifiersToLoad = [this.singleLayerToLoad];
                }

                if (!classifiersToLoad || classifiersToLoad.length === 0) {
                    return;
                }
                
                this.loadGeometries(extent, zoom, classifiersToLoad).then(() => {
                    if (this.singleLayerToLoad && !this.visibleClassifiers.includes(this.singleLayerToLoad)) {
                        this.visibleClassifiers.push(this.singleLayerToLoad);
                    }
                    this.singleLayerToLoad = null;
                });
            },
            strategy: (extent: number[], resolution: number): number[][] => {
                return [this.expandExtent(extent)];
            }
        })

        this.selectService.selectionChanged.subscribe((feature) => {
            if(feature) {
                this.selectedFeature = feature;
            } else {
                this.selectedFeature = null;
            }
        });

        this.selectService.multiSelectionChanged.subscribe((features) => {
            if(features && features.length > 0) {
                this.selectedFeatures = features;
            } else {
                this.selectedFeatures = null;
            }
        });
    }

    clear(): void {
        if (this.source) this.source.refresh();
        this.loaded = [];
    }

    loadSingleLayer(layerId: number, extent: number[], resolution: number) {
        const index = this.visibleClassifiers.indexOf(layerId);
        if (index > -1) {
            return;
        }
        this.singleLayerToLoad = layerId;
        (<any>this.source).loader_(this.expandExtent(extent), resolution);
    }

    removeFeatures(layerId: number): void {
        if (this.visibleClassifiers) {
            const index = this.visibleClassifiers.indexOf(layerId);
            if (index === -1) {
                return;
            }

            this.source.forEachFeature((f: olFeature) => {
                if (f.get('classId') !== layerId) {
                    return;
                }
                this.source.removeFeature(f);
                if(this.loaded.includes(f.getProperties().id)) {
                    const index = this.loaded.indexOf(f.getProperties().id, 0);
                    this.loaded.splice(index, 1);
                }
            });
        }
    }

    findFeature(featureId: number): olFeature {
        if (!this.source) {
            return null;
        }
        return this.source.getFeatures().find(f => f.getProperties().id === featureId);
    }

    protected addFeatureToSource(oFeature: olFeature, geomFeature: Feature) {
        oFeature.setId(geomFeature.id);
        this.source.addFeature(oFeature);
        const objData = JSON.parse(JSON.stringify(geomFeature));
        delete objData.geom;
        oFeature.setProperties(objData);

        this.setFeatureStyle(oFeature, objData);
        if(!this.loaded.includes(objData.id)) {
            this.loaded.push(objData.id);
        }
    }

    private setFeatureStyle(feature: olFeature, objData: Feature) {
        const geomStyle = (objData.geomStyle);
        if (!geomStyle) {
            return;
        }

        const selectionStyle = new Style({
            fill: new Fill({
                color: '#ffffff40'
            }),
            stroke: new Stroke({
                color: '#000000',
                width: 5
            }),
            image: new CircleStyle({
                radius: 15,
                fill: new Fill({ color: '#666666' }),
                stroke: new Stroke({ color: '#bada55', width: 1 })
            })
        });

        if((this.selectedFeature && this.selectedFeature.getId() == objData.id)
        || (this.selectedFeatures && this.selectedFeatures.find(f => f.feature.getId() == objData.id))) {
            feature.setStyle(selectionStyle);
        } else {            
            const customStyle = this.createStyle(feature, objData, geomStyle);
            feature.setStyle(customStyle);
        }

    }

    private createStyle(feature: olFeature, objData: any, geomStyle: GeomStyle) {
        let customStyle = null;
        if (this.type === 'Point') {

            if (geomStyle.customPointIcon) {        //Custom image point style
                const layerCustomIcon = objData['classId'] + '_' + geomStyle.customPointIcon;
                const customIconFromStorage = this.customIcons.find(i => i.url === layerCustomIcon);

                if (customIconFromStorage && this.currentCompanyId) {    //Get custom icon from local storage
                    customStyle = new Style({
                        image: new Icon({
                            size: [40, 40],
                            src: customIconFromStorage.base64Img.toString()
                        })
                    });
                }
                else if (this.currentCompanyId) {     //Get custom icon from directory
                    this.profileFilesService.getCustomIcon(this.currentCompanyId, geomStyle.customPointIcon).then(data => {
                        this.createImageFromBlob(data, geomStyle.customPointIcon, objData['classId']);
                    }, (err) => {
                        console.log(err);
                    });
                }
                else {        //Return basic selected icon since unauthorized user can't access custom icons
                    customStyle = this.createPointStyle(geomStyle);
                }
            }
            else {        //Get usual point style if no custom icon is selected for classifier
                customStyle = this.createPointStyle(geomStyle);
            }
            //Label style -- if label is specified and enabled, creates new style, and turns customStyle into an array of styles to apply for points
            // which includes point style with icon, and label style
            if (geomStyle.labelEnabled && geomStyle.objLabel && objData.objectMapLabel) {
                const textStyle = new Style({
                    text: new Text({
                        text: objData.objectMapLabel,
                        fill: new Fill({ color: geomStyle.labelColor }),
                        font: 'bold ' + geomStyle.labelSize + 'px ' + geomStyle.labelFont,
                        textBaseline: 'top',
                        offsetY: -(geomStyle.pointSize + 5)
                    })
                });
                customStyle = Array.of(customStyle);
                customStyle.push(textStyle);
            }
            //Z-Index
            if (Array.isArray(customStyle)) {
                customStyle[0].setZIndex(objData.zIndex);
            }
            else if (customStyle) {
                customStyle.setZIndex(objData.zIndex);
            }
            //Task style
            var iconText = null;
            var iconColor = null;

            //Add task style if object has multi geom tasks
            if (iconText && iconText && Array.isArray(customStyle)) {
                const taskStyle = new Style({
                    text: new Text({
                        text: String.fromCharCode(parseInt(iconText, 16)),
                        fill: new Fill({ color: iconColor }),
                        font: '900 10px "Font Awesome 5 Free"',
                        offsetX: 7,
                        offsetY: -12
                    }),
                    image: new FontSymbol({
                        form: 'none',
                        radius: +geomStyle.pointRadius,
                        rotation: +geomStyle.pointRotation,
                        gradient: geomStyle.pointGradient,
                        opacity: this.returnAlphaFromRgba("rgb(53,179,119,0.9)"),
                        fill: new Fill({ color: "rgb(53,179,119,0.9)" }),
                    })
                });
                customStyle.push(taskStyle);
            }

        } else {
            customStyle = [new Style({       //If object isn't "point" type
                fill: new Fill({
                    color: geomStyle.fillColor
                }),
                stroke: new Stroke({
                    color: geomStyle.lineColor,
                    width: geomStyle.lineWidth,
                })
            })];
            if (geomStyle.lineStyle === 'dashed') {
                customStyle[0].stroke_.lineDash_ = [8, 8];
            }
            //ol-ext polygon Fill Pattern
            if (geomStyle.fillPattern) {
                customStyle[0].fill_ = new FillPattern({
                    pattern: geomStyle.fillPattern.toString(),
                    color: geomStyle.fillPatternColor,
                    fill: new Fill({ color: geomStyle.fillColor })
                });
            }

            if (geomStyle.strokePattern) {
                customStyle[0].stroke_ = new StrokePattern({
                    pattern: geomStyle.strokePattern.toString(),
                    color: geomStyle.linePatternColor,
                    width: geomStyle.lineWidth,
                    fill: new Fill({ color: geomStyle.lineColor })
                });
            }

            if (geomStyle.lineStyle === 'arrow' && this.type === 'LineString') { // If layer has set its lineStyle to 'arrow', apply the style only to LineString features
                const geometry: any = feature.getGeometry();
                geometry.forEachSegment((start, end) => {
                    var dx = end[0] - start[0];
                    var dy = end[1] - start[1];
                    var rotation = Math.atan2(dy, dx);
                    // arrows
                    customStyle.push(
                        new Style({
                            geometry: new Point(end),
                            image: new Icon({
                                src: 'assets/img/arrow.png',
                                color: geomStyle.lineColor,
                                anchor: [0.75, 0.5],
                                rotateWithView: true,
                                rotation: -rotation,
                                opacity: 0.6
                            }),
                        })
                    );
                });
            }

            if (geomStyle.labelEnabled && geomStyle.objLabel && objData.objectMapLabel) {
                customStyle[0].text_ = new Text({
                    text: objData.objectMapLabel,
                    fill: new Fill({ color: geomStyle.labelColor }),
                    font: 'bold ' + geomStyle.labelSize + 'px ' + geomStyle.labelFont
                });
                if (this.type === 'LineString') {
                    customStyle[0].text_.placement_ = 'line';
                }
            }
            if (customStyle && customStyle[0]) {
                customStyle[0].setZIndex(objData.zIndex);
            }
        }

        return customStyle;
    }

    createPointStyle(geomStyle: GeomStyle) {
        const iconChar = geomStyle.pointUnicode ? String.fromCharCode(parseInt(geomStyle.pointUnicode, 16)) : geomStyle.pointIcon.toString();
        const fontWeight = geomStyle.pointIcon && geomStyle.pointIcon.slice(0, 3) == 'far' ? '400' : '900';    //solid/regular icons
        return new Style({
            text: new Text({
                text: iconChar,   //'\uf3c5'    //'\uf111'
                fill: new Fill({ color: geomStyle.pointColor }),
                font: fontWeight + ' ' + geomStyle.pointSize.toString() + 'px "Font Awesome 5 Free"'
            }),
            image: new FontSymbol({
                form: geomStyle.pointForm.toString(),
                radius: +geomStyle.pointRadius,
                rotation: +geomStyle.pointRotation,
                gradient: geomStyle.pointGradient,
                opacity: this.returnAlphaFromRgba(geomStyle.pointBgColor),
                fill: new Fill({ color: geomStyle.pointBgColor }),
            })
        });
    }

    private returnAlphaFromRgba(color) {
        if (!color || color.substring(0, 4) !== 'rgba') {
            return '1';
        }
        const temp = color.split(',');
        const alpha = temp[temp.length - 1].slice(0, -1);
        return alpha;
    }

    private createImageFromBlob(image: Blob, url: string, classId: number) {
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const layerCustomIcon = classId + '_' + url;
            if (!this.customIcons.some(c => c.url == layerCustomIcon)) {
                this.customIcons.push({
                    url: layerCustomIcon,
                    base64Img: reader.result
                });
                localStorage['customIcons_' + this.currentCompanyId] = JSON.stringify(this.customIcons);
            }
            this.clear();
        }, false);

        if (image) {
            reader.readAsDataURL(image);
        }
    }

    private expandExtent(extent: number[]): number[] {
        const extentExpansionCoef = 0.1;
        const xDiff = extent[2] - extent[0];
        const yDiff = extent[3] - extent[1];

        extent[0] -= xDiff * extentExpansionCoef;
        extent[1] -= yDiff * extentExpansionCoef;
        extent[2] += xDiff * extentExpansionCoef;
        extent[3] += yDiff * extentExpansionCoef;

        return extent;
    }
}
