import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './pages/miscellaneous/not-found/not-found.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@pgis/pages/pages.module').then(m => m.PagesModule)
    },
    {
        path: 'auth',
        loadChildren: () => import('@pgis/auth/auth.module').then(m => m.AuthModule)
    },
    { path: '**', redirectTo: '/misc/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
