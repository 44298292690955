import { Injectable } from '@angular/core';
import { EventsKey } from 'ol/events';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import VectorSource from 'ol/source/Vector';
import Map from 'ol/Map';
import olDraw from 'ol/interaction/Draw';
import * as olObservable from 'ol/Observable';

import * as olSphere from 'ol/sphere';
import Style from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Circle from 'ol/style/Circle';

import olPolygon from 'ol/geom/Polygon';
import olCircle from 'ol/geom/Circle';
import olLineString from 'ol/geom/LineString';

import { Coordinate } from 'ol/coordinate';

@Injectable({
  providedIn: 'root'
})
export class MeasureService {

  map: Map;
  sketch: Feature;
  measureTooltipElement: HTMLElement;
  measureTooltip: Overlay;
  measureSource: VectorSource;
  measureLayer: VectorLayer<any>;
  pointermoveEvent: EventsKey;
  mouseoutEvent: EventsKey;
  drawStartEvent: EventsKey;
  drawEndEvent: EventsKey;
  measureInteraction: olDraw;

  constructor() {
      this.measureSource = new VectorSource();
  }

    enable(map: Map, toolType: string ) {
      this.map = map;
      this.setUp();

      switch(toolType) {
        case 'MeasureDistance':
          return this.setMeasureTool('LineString');

        case 'MeasureArea':
          return this.setMeasureTool('Polygon');
        
        case 'MeasureCircleRadius':
          return this.setMeasureTool('Circle');
      }
  }

  disable() {
      if (this.map) {
        this.map.removeLayer(this.measureLayer);
        olObservable.unByKey(this.pointermoveEvent);

        olObservable.unByKey(this.mouseoutEvent);
        this.measureSource.refresh();
        if (this.measureTooltipElement) {
          this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
          this.measureTooltipElement = null;
          this.map.removeOverlay(this.measureTooltip);
          this.measureTooltip = null;

          const staticTooltips = document.getElementsByClassName('tooltip-static');

          while (staticTooltips.item(0)) {
              const item = staticTooltips.item(0);
              item.parentNode.removeChild(item);
          }
        }
      }

      if (this.measureInteraction) {
        olObservable.unByKey(this.drawStartEvent);
        this.measureInteraction.removeEventListener('drawstart', this.drawStartEvent.listener);
        olObservable.unByKey(this.drawEndEvent);
        this.measureInteraction.removeEventListener('drawend', this.drawEndEvent.listener);
        this.map.removeInteraction(this.measureInteraction);
      }
  }

  private setUp() {
    this.measureLayer = new VectorLayer({
      source: this.measureSource,
      style: new Style({
        fill: new Fill({
          color: 'rgba(255, 255, 255, 0.2)'
        }),
        stroke: new Stroke({
          color: '#ffcc33',
          width: 2
        }),
        image: new Circle({
          radius: 7,
          fill: new Fill({
            color: '#ffcc33'
          })
        })
      })
    });

    this.map.addLayer(this.measureLayer);
  }

  private createMeasureTooltip() {
    if (this.measureTooltipElement) {
      this.measureTooltipElement.remove();
      this.measureTooltipElement.parentNode.removeChild(this.measureTooltipElement);
      this.map.removeOverlay(this.measureTooltip);
    }

    this.measureTooltipElement = document.createElement('div');
    this.measureTooltipElement.className = 'tooltip tooltip-measure';
    this.measureTooltip = new Overlay({
      element: this.measureTooltipElement,
      offset: [-20, -30],
      positioning: 'bottom-center'
    });

    this.map.addOverlay(this.measureTooltip);
  }

  private setMeasureTool(toolType) {
    this.measureInteraction = new olDraw({
      source: this.measureSource,
      type: toolType
    });
    this.map.addInteraction(this.measureInteraction);
    this.createMeasureTooltip();

    let listener;
    this.drawStartEvent = this.measureInteraction.on('drawstart', (drawStartEvt) => {
      this.sketch = drawStartEvt.feature;
      let tooltipCoord = {} as Coordinate;
      listener = this.sketch.getGeometry().on('change', (evt) => {
        const geom = evt.target;
        let output = '';
        if (geom instanceof olPolygon) {
          output = this.formatArea(geom);
          tooltipCoord = geom.getInteriorPoint().getCoordinates();
        }
        if (geom instanceof olLineString) {
          output = this.formatLength(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        if (geom instanceof olCircle) {
          output = this.formatRadius(geom);
          tooltipCoord = geom.getLastCoordinate();
        }
        this.measureTooltipElement.innerHTML = output;
        this.measureTooltip.setPosition(tooltipCoord);
      });
    });

    this.drawEndEvent = this.measureInteraction.on('drawend', () => {
      this.measureTooltipElement.className = 'tooltip tooltip-static';
      this.measureTooltip.setOffset([0, 0]);
      // unset sketch
      this.sketch = null;
      // unset tooltip so that a new one can be created
      this.measureTooltipElement = null;
      this.createMeasureTooltip();
      olObservable.unByKey(listener);
    });

    return this.measureInteraction;
  }

          //// UTILS ////
  formatArea(polygon) {
    const area = olSphere.getArea(polygon);
    let output;
    const valueHa = (Math.round(area * 100) / 100) * 0.0001;
    output = Number(valueHa.toFixed(2)) +
        ' ' + 'ha';
    return output;
  }

  formatLength(line) {
    const length = olSphere.getLength(line);
    let output;
    if (length > 100) {
        output = (Math.round(length / 1000 * 100) / 100) +
            ' ' + 'km';
    } else {
        output = (Math.round(length * 100) / 100) +
            ' ' + 'm';
    }
    return output;
  }

  formatRadius(circle) {
    const radius = circle.getRadius();
    let output;
    if (radius > 100) {
        output = (Math.round(radius / 1000 * 100) / 100) +
            ' ' + 'km';
    } else {
        output = (Math.round(radius * 100) / 100) +
            ' ' + 'm';
    }
    return output;
  }
}
