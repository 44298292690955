import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Classifier } from '@pgis/shared/models/classifiers.model';
import { lastValueFrom } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ClassifiersService extends BaseService<Classifier> {

  constructor(httpClient: HttpClient) { 
    super(httpClient, '/api/v1/classifiers')
  }

  async getChildClassifiers(baseId: number): Promise<Classifier[]> {
    return await lastValueFrom(this.httpClient.get<Classifier[]>(`${this.baseApiUrl}/${baseId}`));
  }

  async getContractorPersonsMapLayerClassifiers(): Promise<Classifier[]> {
    return await lastValueFrom(this.httpClient.get<Classifier[]>(`${this.baseApiUrl}/contractor-persons-map-layers`));
}
}
