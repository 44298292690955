<nz-divider nzText="Uzdevuma attēli"></nz-divider>
<h6 *ngIf="!imageArray || imageArray.length == 0"
    class="text-center">Uzdevumam nav pievienots neviens attēls!</h6>

<nz-carousel nzAutoPlay
            *ngIf="imageArray && imageArray.length != 0">
    <div nz-carousel-content *ngFor="let taskImage of imageArray">
        <img [nzSrc]="taskImage.value" nz-image>
        <label class="bold mb-2">Pievienots: {{ formatDate(taskImage.added) }}</label>
    </div>
</nz-carousel>
<nz-divider nzText="Statusu vēsture"></nz-divider>
<nz-table #taskStatusTable 
        [nzData]="task.taskStatusHistory" 
        nzSize="small"
        [nzFrontPagination]="false"
        [nzBordered]="true"
        [nzScroll]="{ y: '18rem' }"
        class="w-100">
    <thead>
        <tr>
            <th>Statuss</th>
            <th>Datums</th>
            <th>Komentārs</th>
            <th>Lietotājs</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let status of taskStatusTable.data">
            <td>{{ status.classifierStatusName }}</td>
            <td>{{ formatDate(status.statusChanged) }}</td>
            <td>{{ status.comment }}</td>
            <td>{{ status.userName }}</td>
        </tr>
    </tbody>
</nz-table>