import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupByFilter'
})
export class GroupPipe implements PipeTransform {

  transform(collection: any[], property: string): any[] {
    if (!collection) {
      return null;
    }
    const groupedCollection = collection.reduce((previous, current) => {
      if (!current[property] && !previous['no-group-key']) {
        previous['no-group-key'] = [current];
      }
      else if (!current[property] && previous['no-group-key']) {
        previous['no-group-key'].push(current);
      }
      else if (!previous[current[property]]) {
        previous[current[property]] = [current];
      } else {
        previous[current[property]].push(current);
      }

      return previous;
    }, {});
    // this will return an array of objects, each object containing a group of objects
    return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key], id: this.convertToSlug(key) + Math.floor(Math.random() * 100000) }));
  }

  private convertToSlug(text: string): string {
    return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '')
        ;
  }
}
