import { Component } from '@angular/core';

@Component({
  selector: 'app-add-task-file-modal',
  templateUrl: './add-task-file-modal.component.html',
  styleUrls: ['./add-task-file-modal.component.less']
})
export class AddTaskFileModalComponent {

  description: string = '';
  includeInReport: boolean = true;
}
