import { Inject, Injectable } from '@angular/core';

import { Classifier } from '@pgis/shared/models/classifiers.model';

import { LayerSource } from './layer.source';

import VectorLayer from 'ol/layer/Vector';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root'
})
export class VectorSourceProviderService {

  constructor(@Inject(LayerSource) private layerSources: LayerSource[]) { }

  initAll(): void {
    this.layerSources.forEach(source => {
      source.initSource();
    });
  }

  getAllLayers(): VectorLayer<any>[] {
    return this.layerSources.map(l => l.getLayer());
  }

  getAllLayerSources(): VectorSource[] {
    return this.layerSources.map(l => l.source);
  }

  setVisibleClassifiers(visibleClassifiers: number[]): void {
    this.layerSources.forEach(source => {
        source.visibleClassifiers = visibleClassifiers;
    });
  }

  getVisibleClassifiers(): number[] {
    return this.layerSources[0].visibleClassifiers;
  }

  findFeature(id: number): Feature {
    for (let index = 0; index < this.layerSources.length; index++) {
        const feature = this.layerSources[index].findFeature(id);
        if (feature) {
            return feature;
        }
    }
    return null;
  }

  clearAll(): void {
    this.layerSources.forEach(source => {
        source.clear();
    });
  }

  toggleLayer(classifier: Classifier, extent: number[], resolution: number): void {
    if (classifier.checked) {
        this.layerSources.forEach(source => {
            source.loadSingleLayer(classifier.id, extent, resolution);
        });
        return;
    }

    this.clearOldGeometries(classifier);
    this.layerSources.forEach(source => {
      if(source.visibleClassifiers.includes(classifier.id)) {
        const index = source.visibleClassifiers.indexOf(classifier.id, 0);
                    source.visibleClassifiers.splice(index, 1);
      }
    });
  }

  clearOldGeometries(classifier: Classifier): void {
    this.layerSources.forEach(source => {
        source.removeFeatures(classifier.id);
    });

    const firstSource = this.layerSources[0];
    if (this.layerSources[0] && firstSource.visibleClassifiers) {
        const index = firstSource.visibleClassifiers.indexOf(classifier.id);
        if (index === -1) {
            return null;
        }
        firstSource.visibleClassifiers.splice(index, 1);
    }
  }
}
