import { Component } from '@angular/core';

import { Classifier } from '@pgis/shared/models/classifiers.model';

@Component({
  selector: 'app-multi-select-layers-modal',
  templateUrl: './multi-select-layers-modal.component.html',
  styleUrls: ['./multi-select-layers-modal.component.less']
})
export class MultiSelectLayersModalComponent {

  enabledLayers: Classifier[] = [];
  selectedLayerIds: number[] = [];

  setLayers(event) {
    this.selectedLayerIds = event;
  }
}
