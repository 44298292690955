import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { LoaderService } from '@pgis/core/services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private loaderService: LoaderService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.loaderService.pendingRequests++;
    this.loaderService.display(true);
    return next.handle(request).pipe(tap(event => {
      if(event instanceof HttpResponse) {
        if(--this.loaderService.pendingRequests <= 0) {
          this.loaderService.pendingRequests = 0;
          this.loaderService.display(false);
        }
      }
    }, error => {
      if(error instanceof HttpErrorResponse) {
        if(--this.loaderService.pendingRequests <= 0) {
          this.loaderService.pendingRequests = 0;
          this.loaderService.display(false);
        }
      }
    }));
  }
}
