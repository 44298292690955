import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

import { MapService } from './services/map.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';
import { GeometriesService } from '@pgis/core/services/geometries.service';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Classifier } from '@pgis/shared/models/classifiers.model';
import { ClassifiersService } from '@pgis/core/services/classifiers.service';

@Component({
    selector: 'pgis-map',
    templateUrl: 'map.component.html',
    styleUrls: ['map.component.less']
})
export class MapComponent implements OnInit {

    constructor(private modalService: NzModalService,
        private classifiersService: ClassifiersService,
        private notificationService: NzNotificationService,
        private mapService: MapService,
        private activatedRoute: ActivatedRoute,
        private geometriesService: GeometriesService) { }

    async ngOnInit(): Promise<void> {
        const layerId = this.activatedRoute.snapshot.params.layerId;
        const objectId = this.activatedRoute.snapshot.params.objectId;

        if(!layerId || !objectId) {
            this.mapService.initMap();
            return;
        }

        try {
            const extent = await this.geometriesService.getObjectExtent(layerId, objectId);
            this.mapService.initMap(extent).then(() => {
                const layer = (<any>this.mapService.mapObjectClassifiers.map<Classifier[]>(c => c.childClassifiers)).flat().find(c => c.id === parseInt(layerId, 10));
                const selectedLayers = JSON.parse(localStorage.getItem('selectedLayers')) || [];

                if(!selectedLayers.includes(layer.id)) { // check if layer is not already active
                    layer.checked = true;
                    selectedLayers.push(layer.id);
                    localStorage.setItem('selectedLayers', JSON.stringify(selectedLayers));

                    this.mapService.toggleObjectLayer(layer);
                }
            });
        } catch (error) {
            this.notificationService.error('Kļūda', 'Neizdevās parādīt objektu kartē!');
            this.mapService.initMap();
        }
    }
}