import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './base.service';
import { Layer } from '@pgis/shared/models/layer.model';
import { lastValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LayerService extends BaseService<Layer> {
    constructor(httpClient: HttpClient) {
        super(httpClient, '/api/v1/layers/');
    }

    async getActiveBaseLayers(): Promise<Layer[]> {
        return await lastValueFrom(this.httpClient.get<Layer[]>(this.baseApiUrl + 'base'));
    }
}
