import { EventEmitter, Injectable } from '@angular/core';

import Map from 'ol/Map';
import Geolocation from 'ol/Geolocation';
import { Feature } from 'ol';
import Style from 'ol/style/Style';
import CircleStyle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';

@Injectable({
  providedIn: 'root'
})
export class MapGeolocationService {

  map: Map;

  geolocation: Geolocation;
  geolocationLayer: VectorLayer<VectorSource<Geometry>>;
  isTrackingEnabled: boolean = false;

  geolocationReady: EventEmitter<void> = new EventEmitter<void>();

  constructor() { }

  setupGeolocation(map: Map): void {
    const view = map.getView();

    this.geolocation = new Geolocation({
      trackingOptions: {
        enableHighAccuracy: true
      },
      projection: view.getProjection()
    });

    this.geolocation.on('error', (error) => {
      console.error(error.message);
    });

    const accuracyFeature = new Feature();
    this.geolocation.on('change:accuracyGeometry', () => {
      accuracyFeature.setGeometry(this.geolocation.getAccuracyGeometry());
    });

    const positionFeature = new Feature();
    positionFeature.setStyle(new Style({
      image: new CircleStyle({
        radius: 6,
        fill: new Fill({
          color: '#3399CC'
        }),
        stroke: new Stroke({
          color: '#FFFFFF',
          width: 2
        })
      })
    }));

    this.geolocation.on('change:position', () => {
      const extentSize = 200;
      const locationPoint = this.geolocation.getPosition();
      positionFeature.setGeometry(locationPoint ? new Point(locationPoint) : null);
      if(this.isTrackingEnabled) {
        const currentView = this.map.getView();
        const mapSize = this.map.getSize();
        const bufferedExtent = [];
        bufferedExtent.push(locationPoint[0] - extentSize);
        bufferedExtent.push(locationPoint[1] - extentSize);
        bufferedExtent.push(locationPoint[0] + extentSize);
        bufferedExtent.push(locationPoint[1] + extentSize);
        currentView.fit(bufferedExtent, { size: mapSize });
      }
    });

    this.geolocationLayer = new VectorLayer({
      source: new VectorSource({
        features: [positionFeature, accuracyFeature]
      })
    });
    this.geolocationLayer.set('layerName', 'geolocation');

    const isTrackingEnabled = localStorage.getItem('isTrackingEnabled') === 'true';
    this.geolocation.setTracking(isTrackingEnabled);
    map.addLayer(this.geolocationLayer);
    this.map = map;
    this.geolocationReady.emit();
  }

  setTracking(enabled: boolean): void {
    if(!this.geolocation) {
      return;
    }

    this.geolocation.setTracking(enabled);
    if(!enabled) {
      this.map.removeLayer(this.geolocationLayer);
    } else {
      let mapLayers = this.map.getLayers().getArray();

      if(!mapLayers.find(m => m['ol_uid'] == this.geolocationLayer['ol_uid'])) {
        this.map.addLayer(this.geolocationLayer);
      } else {
        this.geolocation.setTracking(false);
        this.map.removeLayer(this.geolocationLayer);
        this.map.addLayer(this.geolocationLayer);
        this.geolocation.setTracking(true);
      }
    }
  }
}
