import { Component } from '@angular/core';
import { TaskService } from '@pgis/core/services/task.service';
import { TaskFile } from '@pgis/shared/models/task-file.model';
import * as moment from 'moment';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-task-files-modal',
  templateUrl: './task-files-modal.component.html',
  styleUrls: ['./task-files-modal.component.less']
})
export class TaskFilesModalComponent {

  taskFiles: TaskFile[];

  constructor(private tasksService: TaskService,
    private notificationsService: NzNotificationService) {
  }

  async downloadFile(fileId: number, taskId: number) {
    try {
      await this.tasksService.downloadTaskFile(taskId, fileId);
      return;
    } catch (err) {
      this.notificationsService.error('Kļūda', 'Kļūda lejupielādējot failu');
      return;
    }
  }

  formatDate(date: string): string{
    return moment(new Date(date)).format('DD.MM.yyyy, HH:mm');
  }
}
