import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { AuthService } from '@pgis/core/services/auth.service';

@Directive({ selector: '[isGranted]' })
export class IsGrantedDirective implements OnDestroy {

    private destroy$ = new Subject<void>();

    private hasView = false;

    constructor(private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authService: AuthService) {
    }

    @Input() set isGranted(roles: string[]) {
        const can = this.authService.useHasRole(roles);
        if (can && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!can && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
