import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import en from '@angular/common/locales/en';
import lv from '@angular/common/locales/lv';
import { JwtModule } from '@auth0/angular-jwt';

import { NZ_I18N, lv_LV, NZ_DATE_CONFIG } from 'ng-zorro-antd/i18n';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@pgis/shared/shared.module';
import { BaseUrlInterceptor } from './interceptors/base-url.interceptor';
import { AuthErrorInterceptor } from './interceptors/auth.interceptor';
import { environment } from '@env/environment';
import { LoaderInterceptor } from './interceptors/loader.interceptor';

registerLocaleData(en);
registerLocaleData(lv);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        SharedModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        JwtModule.forRoot({
            config: {
                tokenGetter: () => localStorage.getItem('access_token'),
                allowedDomains: [environment.apiBaseUrl]
            }
        })
    ],
    providers: [
        { provide: NZ_I18N, useValue: lv_LV },
        {
            provide: NZ_DATE_CONFIG,
            useValue: {
                firstDayOfWeek: 1
            }
        },
        { provide: APP_BASE_HREF, useValue: '/' },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: BaseUrlInterceptor,
        //     multi: true
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptor,
            multi: true
        },],
    bootstrap: [AppComponent]
})
export class AppModule { }
