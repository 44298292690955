<textarea nz-input
            [(ngModel)]="description"
            placeholder="Apraksts"
            [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>

<div class="switch-container mt-2">
    <nz-switch [(ngModel)]="includeInReport"
                nzCheckedChildren="Jā"
                nzUnCheckedChildren="Nē"
                class="me-2"></nz-switch>
    <span>Iekļaut pārskatā</span>
</div>
