import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature } from '@pgis/shared/models/feature.model';
import { GeomsWithStyles } from '@pgis/shared/models/geoms-with-styles.model';
import { Coordinate } from 'ol/coordinate';
import { lastValueFrom } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class GeometriesService extends BaseService<any> {

  constructor(httpClient: HttpClient) { 
    super(httpClient, '/api/v1/geometries');
  }

  async getGeometries(type: string, extent: number[], zoom: number, classifiersToLoad: number[], loadedGeoms: any): Promise<GeomsWithStyles> {
    return await lastValueFrom(this.httpClient.post<GeomsWithStyles>(this.baseApiUrl, 
      {
        minx: extent[0],
        miny: extent[1],
        maxx: extent[2],
        maxy: extent[3],
        classifiersToLoad: classifiersToLoad || [],
        loadedGeometries: loadedGeoms || [],
        zoom: zoom,
        type: type
      }));
  }

  async getGeometry(geomId: number): Promise<Feature> {
    return await lastValueFrom(this.httpClient.get<Feature>(`${this.baseApiUrl}/${geomId}`));
  }

  async getObjectExtent(layerId: number, objectId: number): Promise<number[]> {
    return await lastValueFrom(this.httpClient.get<number[]>(`${this.baseApiUrl}/extent/${layerId}/${objectId}`));
  }

  async getLayerExtent(layerId: number): Promise<number[]> {
    return await lastValueFrom(this.httpClient.get<number[]>(`${this.baseApiUrl}/extent/${layerId}`));
  }

  async getWazeLinkCoordinates(layerId: number, objectId: number): Promise<Coordinate> {
    return await lastValueFrom(this.httpClient.get<Coordinate>(`${this.baseApiUrl}/waze-coords/${layerId}/${objectId}`));
  }
}
