import { Component } from '@angular/core';

@Component({
  selector: 'app-status-change-comment-modal',
  templateUrl: './status-change-comment-modal.component.html',
  styleUrls: ['./status-change-comment-modal.component.less']
})
export class StatusChangeCommentModalComponent {
  statusChangeComment: string;
}
