import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContractorPersonChangeByObjectIds } from '@pgis/shared/models/contractor-person-change-by-object-ids.model';
import { MassContractorPersonChange } from '@pgis/shared/models/mas-contractor-person-change.model';
import { TaskImage } from '@pgis/shared/models/task-image.model';
import { TaskStatusChangeByObjectIds } from '@pgis/shared/models/task-status-change-by-object-ids.model';
import { TaskStatus } from '@pgis/shared/models/task-status.model';
import { Task } from '@pgis/shared/models/task.model';
import { lastValueFrom } from 'rxjs';
import { BaseService } from './base.service';
import { TaskFile } from '@pgis/shared/models/task-file.model';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class TaskService extends BaseService<any> {

  constructor(httpClient: HttpClient) { 
    super(httpClient, '/api/v1/tasks');
  }

  async getTasks(): Promise<Task[]> {
    return await lastValueFrom(this.httpClient.get<Task[]>(this.baseApiUrl));
  }

  async changeTaskStatus(taskStatusModel: TaskStatus): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/change-status`, taskStatusModel));
  }

  async changeTaskStatusByObjectIds(statusChangeModel: TaskStatusChangeByObjectIds): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/object-ids-status-change`, statusChangeModel));
  }

  async changeContractorPerson(task: Task): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/change-contractor-person`, task));
  }

  async massContractorPersonChange(changeModel: MassContractorPersonChange): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/mass-contractor-person-change`, changeModel));
  }

  async changeContractorPersonByObjectIds(contractorPersonChangeModel: ContractorPersonChangeByObjectIds): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/object-ids-contractor-person-change`, contractorPersonChangeModel));
  }

  async getTaskFiles(taskId: number): Promise<TaskFile[]> {
    return await lastValueFrom(this.httpClient.get<TaskFile[]>(`${this.baseApiUrl}/files/${taskId}`));
  }

  async addTaskFile(files: FormData, taskId: number): Promise<any> {
    return await lastValueFrom(this.httpClient.post<any>(`${this.baseApiUrl}/add-file/${taskId}`, files));
  }

  async downloadTaskFile(taskId: number, fileId: number): Promise<any> {
    return await lastValueFrom(this.httpClient.get<any>(`${this.baseApiUrl}/download-file/${taskId}/${fileId}`, 
    { responseType: 'blob' as 'json', observe: 'response'}))
    .then((response) => {
      try {
        const contentDisposition = response.headers.get('content-disposition');
        const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        saveAs(response.body, fileName.replace(/^\"+|\"+$/g, ''));
      } catch {
        console.error('Couldn\'t save file');
      }
    });
  }

  async getTaskImageThumbnails(taskId: number): Promise<TaskImage[]> {
    return await lastValueFrom(this.httpClient.get<TaskImage[]>(`${this.baseApiUrl}/images-thumbnails/${taskId}`));
  }

  async getTaskStatusCountMap(isContractorAdmin: boolean): Promise<Map<number, number>> {
    return await lastValueFrom(this.httpClient.get<Map<number, number>>(`${this.baseApiUrl}/status-count-map/${isContractorAdmin}`));
  }
}
