import { EventEmitter, Injectable, Output } from '@angular/core';
import * as _ from 'lodash';

import View from 'ol/View';
import * as Proj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import Map from 'ol/Map';
import { defaults as defaultControls } from 'ol/control';

import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import XYZ from 'ol/source/XYZ';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import ImageArcGISRest from 'ol/source/ImageArcGISRest';
import ImageLayer from 'ol/layer/Image';
import { StyleLike } from 'ol/style/Style';
import Select from 'ol/interaction/Select';
import Draw from 'ol/interaction/Draw';

import { LayerService } from '@pgis/core/services/layer.service';
import { MapGeolocationService } from './map.geolocation.service';
import { VectorSourceProviderService } from './vector-source-provider.service';
import { ClassifiersService } from '@pgis/core/services/classifiers.service';
import { GeometriesService } from '@pgis/core/services/geometries.service';
import { SelectService } from './select.service';

import { Classifier } from '@pgis/shared/models/classifiers.model';
import { MapActions } from '@pgis/shared/models/map-actions.enum';
import { Subscription } from 'rxjs';
import { MeasureService } from './measure.service';
import Feature from 'ol/Feature';
import VectorSource from 'ol/source/Vector';


@Injectable({ providedIn: 'root' })
export class MapService {

    @Output()
    mapInitialized: EventEmitter<any> = new EventEmitter();

    map: Map;
    view: View;
    previousResolution: number = 0;
    zoomingOut: boolean = false;
    zoomEventFired: boolean = false;

    baseLayers = [];
    overlayLayers = [];
    layers = [];

    objectSelectedSubscription: Subscription;
    objectDeselectedSubscription: Subscription;
    multiObjectsDeselectedSubscription: Subscription;
    multiObjectsByPolygonDeselectedSubscription: Subscription;
    multiSelectPolygonDrawnSubscription: Subscription;

    mapObjectClassifiers: Classifier[];

    selectedFeatureDefaultStyle: StyleLike;

    currentAction: MapActions;

    measureInteraction: Draw;

    statusChanged: boolean = false;

    constructor(private baseLayersService: LayerService,
        private geometriesService: GeometriesService,
        private classifiersService: ClassifiersService,
        private geolocationService: MapGeolocationService,
        private vectorSourceProviderService: VectorSourceProviderService,
        private selectService: SelectService,
        private measureService: MeasureService) { }

    async initMap(extent?: number[]) {
        this.layers = [];
        const baseLayers = await this.getBaseLayers();
        this.vectorSourceProviderService.initAll();
        this.layers.push(...this.vectorSourceProviderService.getAllLayers());
        this.mapObjectClassifiers = await this.classifiersService.getContractorPersonsMapLayerClassifiers();

        const selectedLayers: number[] = JSON.parse(localStorage.getItem('selectedLayers')) || [];
        this.vectorSourceProviderService.setVisibleClassifiers(selectedLayers);

        proj4.defs('EPSG:3059',
        '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        register(proj4);

        const lastMapView = JSON.parse(localStorage.getItem('lastMapView'));
        if (lastMapView) {
            lastMapView.constrainResolution = true;
            this.view = new View(lastMapView);
        } else {
            const defaultView = {
                center: Proj.fromLonLat([23.9890828, 56.9713962]),
                zoom: 10,
                constrainResolution: true
            };
            this.view = new View(defaultView);
        }

        this.map = new Map({
            target: 'map',
            layers: baseLayers.map(f => f.olLayer),
            view: this.view,
            controls: defaultControls({ attribution: false })
        });

        this.layers.forEach(layer => {
            this.map.addLayer(layer);
        })

        if(extent) {
            // zoom to extent
            this.zoomToExtent(extent);
        }

        this.geolocationService.setupGeolocation(this.map);
        if(this.selectService.selectObjectInteraction) {
            this.disableSelectObject(); // disable select interaction, if it is inited before, to reset it after view change
        }

        this.setCurrentAction(MapActions.SelectObject);
        this.setSelectTool();

        this.map.once('postrender',
            () => {
                this.map.updateSize();
                this.map.renderSync();
            }
        );

        this.map.on('moveend', () => {
            if (this.zoomEventFired) {
                if (!this.zoomingOut) {
                    this.reloadLayerData();
                }
            }
            this.rememberView();
        });

        this.map.getView().on('change:resolution', e => {
            this.zoomEventFired = true;
            this.zoomingOut = this.previousResolution < e.oldValue;
            this.previousResolution = e.oldValue;
        });
        this.mapInitialized.emit();
    }

    reloadLayerData() {
        this.vectorSourceProviderService.clearAll();
    }

    async getBaseLayers(): Promise<any[]> {
        const baseLayerConfigs = await this.baseLayersService.getActiveBaseLayers();

        const layers: any[] = [];
        const mapLayers = JSON.parse(localStorage.getItem('selectedBaseAndOverlayLayers'));
        baseLayerConfigs.forEach(layer => {
            if (layer.layerType !== 'TileLayer') {
                return;
            }

            switch (layer.sourceType) {
                case 'XYZ':
                    const xConfig = JSON.parse(layer.sourceJson);
                    const xSource = new XYZ({ ...xConfig, crossOrigin: 'anonymous' });

                    layers.push({
                        name: layer.name,
                        group: layer.group,
                        overlay: layer.overlay,
                        olLayer: new TileLayer({
                            source: xSource,
                            visible: ((!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === layer.name).length !== 0) //Remembered selected layer
                                    || (_.isEmpty(mapLayers) && layer.id === 1)) ? true : false   //default Google map layer
                        })
                    });
                    break;

                case 'BingMaps':
                    const bSource = new BingMaps(JSON.parse(layer.sourceJson));
                    layers.push({
                        name: layer.name,
                        group: layer.group,
                        overlay: layer.overlay,
                        olLayer: new TileLayer({
                            source: bSource,
                            visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === layer.name).length !== 0) ? true : false
                        })
                    });
                    break;

                case 'TileWMS':
                case 'TopodatiWMS':
                    const layerConfig = JSON.parse(layer.sourceJson);
                    const tSource = new TileWMS({ ...layerConfig, crossOrigin: 'anonymous' });
                    const wmsLayer = new TileLayer({
                        source: tSource,
                        zIndex: layer.overlayOrder,
                        visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === layer.name).length !== 0) ? true : false
                    });

                    layers.push({
                        name: layer.name,
                        group: layer.group,
                        overlay: layer.overlay,
                        olLayer: wmsLayer
                    });
                    break;

                case 'ImageArcGISRest':
                    const layerSource = JSON.parse(layer.sourceJson);
                    const iSource = new ImageArcGISRest({ ...layerSource, crossOrigin: 'anonymous' });
                    const arcgisLayer = new ImageLayer({
                        source: iSource,
                        zIndex: layer.overlayOrder,
                        visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === layer.name).length !== 0) ? true : false
                    });

                    layers.push({
                        name: layer.name,
                        group: layer.group,
                        overlay: layer.overlay,
                        olLayer: arcgisLayer
                    });
                    break;

                case 'ImageWMS':
                    const iwConfig = JSON.parse(layer.sourceJson);
                    const iwSource = new ImageWMS({ ...iwConfig, crossOrigin: 'anonymous' });
                    const iwmsLayer = new ImageLayer( {
                        source: iwSource,
                        zIndex: layer.overlayOrder,
                        visible: (!_.isEmpty(mapLayers) && _.filter(mapLayers, l => l.name === layer.name).length !== 0) ? true : false
                    });

                    layers.push({
                        name: layer.name,
                        group: layer.group,
                        overlay: layer.overlay,
                        olLayer: iwmsLayer
                    });
                    break;
            }
        });

        let switchedOnLayers = layers.filter(l => l.olLayer.getVisible());
        let localStorageLayers = [];
        switchedOnLayers.forEach(l => {
            let clone = _.clone(l);
            delete clone.olLayer;
            localStorageLayers.push(clone);
        });

        localStorage.setItem('selectedBaseAndOverlayLayers', JSON.stringify(localStorageLayers));

        this.baseLayers = _.filter(layers, { 'overlay': false });
        this.overlayLayers = _.filter(layers, { 'overlay': true });
        return layers;
    }

    toggleObjectLayer(layer: Classifier) {
        const extent = this.map.getView().calculateExtent(this.map.getSize());
        const resolution = this.map.getView().getResolution();

        this.vectorSourceProviderService.toggleLayer(layer, extent, resolution);
    }

    rememberView() {
        const viewZoom = this.map.getView().getZoom();
        const currentView = {
            center: this.map.getView().getCenter(),
            zoom: viewZoom,
        };

        localStorage.setItem('lastMapView', JSON.stringify(currentView));
    }

    async zoomToLayer(layer: Classifier) {
        try {
            const layerExtent = await this.geometriesService.getLayerExtent(layer.id);
            this.toggleObjectLayer(layer);
            this.zoomToExtent(layerExtent);
        } catch (error) {
            console.error(error);
        }
    }

    setSelectTool() {
        this.setCurrentAction(MapActions.SelectObject);

        this.selectService.enable(this.map, MapActions.SelectObject);

        this.objectSelectedSubscription = this.selectService.objectSelected.subscribe(() => {
            this.selectedFeatureDefaultStyle = this.selectService.selectedFeatureDefaultStyle;
        });

        this.objectDeselectedSubscription = this.selectService.objectDeselected.subscribe((id) => {
            if(this.statusChanged) {
                this.reloadLayerData();
                this.statusChanged = false;
                return;
            }
            let deselectedFeature = this.vectorSourceProviderService.findFeature(id);
            if(!deselectedFeature) {
                return;
            }
            deselectedFeature.setStyle(this.selectedFeatureDefaultStyle);
        });
    }

    setSelectMultipleTool(selectedLayerIds: number[]) {
        this.setCurrentAction(MapActions.SelectMultipleObjects);

        this.selectService.enable(this.map, MapActions.SelectMultipleObjects, selectedLayerIds);

        this.multiObjectsDeselectedSubscription = this.selectService.multiObjectsDeselected.subscribe((features) => {
            if(this.statusChanged) {
                this.reloadLayerData();
                this.statusChanged = false;
                return;
            }
            features.forEach((feature) => {
                let deselectedFeature = this.vectorSourceProviderService.findFeature(<number>feature.feature.getId());
                deselectedFeature.setStyle(feature.style);
            });
        });
    }

    setSelectMultipleByPolygonTool(selectedLayerIds: number[]) {
        this.setCurrentAction(MapActions.SelectMultipleObjectsByPolygon);

        this.selectService.enable(this.map, MapActions.SelectMultipleObjectsByPolygon, selectedLayerIds);

        this.multiObjectsByPolygonDeselectedSubscription = this.selectService.multiObjectsDeselected.subscribe((features) => {
            if(this.statusChanged) {
                this.reloadLayerData();
                this.statusChanged = false;
                return;
            }
            features.forEach((feature) => {
                let deselectedFeature = this.vectorSourceProviderService.findFeature(<number>feature.feature.getId());
                deselectedFeature.setStyle(feature.style);
            });
        });

        this.multiSelectPolygonDrawnSubscription = this.selectService.multiSelectPolygonDrawn.subscribe(() => {
            this.selectService.visibleFeatures = this.getVisibleFeatures();
        });
    }

    setMeasureTool(type: 'MeasureDistance' | 'MeasureArea' | 'MeasureCircleRadius') {
        this.setCurrentAction(type as MapActions);
        this.measureService.enable(this.map, type);
    }

    private zoomToExtent(extent: number[]): void {
        const view = this.map.getView();
        const mapSize = this.map.getSize();
        if (!view || !mapSize) {
            return;
        }

        view.fit(extent, { size: mapSize, maxZoom: 28 });
        this.rememberView();
    }


    private disableSelectObject(): void {
        this.selectService.resetSelection();
        this.selectService.disable(MapActions.SelectObject);

        if(this.objectSelectedSubscription) {
            this.objectSelectedSubscription.unsubscribe();
        }
        if(this.objectDeselectedSubscription) {
            this.objectDeselectedSubscription.unsubscribe();
        }
    }

    private disableSelectMultipleObjects(): void {
        this.selectService.disable(MapActions.SelectMultipleObjects);
        
        if(this.multiObjectsDeselectedSubscription) {
            this.multiObjectsDeselectedSubscription.unsubscribe();
        }
    }

    private disableSelectMultipleObjectsByPolygon(): void {
        this.selectService.disable(MapActions.SelectMultipleObjectsByPolygon);
        if(this.multiObjectsDeselectedSubscription) {
            this.multiObjectsDeselectedSubscription.unsubscribe();
        }
    }

    private setCurrentAction(action: MapActions) {
        this.currentAction = action;

        switch(action) {
            case MapActions.SelectObject:
                this.measureService.disable();
                this.disableSelectMultipleObjects();
                this.disableSelectMultipleObjectsByPolygon();
            break;

            case MapActions.SelectMultipleObjects:
                this.measureService.disable();
                this.disableSelectObject();
                this.disableSelectMultipleObjectsByPolygon();
            break;

            case MapActions.SelectMultipleObjectsByPolygon:
                this.measureService.disable();
                this.disableSelectObject();
                this.disableSelectMultipleObjects();
            break;
            
            case MapActions.MeasureArea:
            case MapActions.MeasureDistance:
            case MapActions.MeasureCircleRadius:
                this.disableSelectObject();
                this.measureService.disable();
                this.disableSelectMultipleObjects();
                this.disableSelectMultipleObjectsByPolygon();
            break;
        }
    }

    private getVisibleFeatures(): Feature[] {
        const visibleFeatures: Feature[] = [];
        const extent = this.map.getView().calculateExtent(this.map.getSize());
        this.vectorSourceProviderService.getAllLayerSources().forEach((source: VectorSource) => {
            source.forEachFeatureInExtent(extent, (feature: Feature) => {
                visibleFeatures.push(feature);
            });
        });

        return visibleFeatures;
    }
}
