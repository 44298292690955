import { Injectable } from '@angular/core';

import { GeometriesService } from '@pgis/core/services/geometries.service';
import { ProfileFilesService } from '@pgis/core/services/profile-files.service';
import { AuthService } from '@pgis/core/services';
import { SelectService } from './select.service';

import { GeomsWithStyles } from '@pgis/shared/models/geoms-with-styles.model';

import { LayerSource } from './layer.source';

import WKT from 'ol/format/WKT';
import { Geometry } from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import olFeature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';

@Injectable({
  providedIn: 'root'
})
export class PointSourceService extends LayerSource {

  source: VectorSource<Geometry>;
  loaded: number[] = [];
  type: string = 'Point';

  constructor(private geometriesService: GeometriesService,
    profileFilesService: ProfileFilesService,
    authService: AuthService,
    selectService: SelectService) { 
    super(profileFilesService, authService, selectService);
    }

    loadGeometries(extent: number[], zoom: number, classifiersToLoad: number[]): Promise<void> {
      return this.geometriesService.getGeometries('points', extent, zoom, classifiersToLoad, this.loaded).then((geomFeatures: GeomsWithStyles) => {
        if(!geomFeatures.features) {
          return;
        }
  
        geomFeatures.features.forEach((feature) => {
          feature.geomStyle = geomFeatures.geomStyles[feature.geomStyleIndex].geomStyle;
  
          const format = new WKT();
          const geom = format.readGeometry(feature.geom);
  
          const oFeature = new olFeature({
            geometry: geom,
            name: feature.name
          });
  
          this.addFeatureToSource(oFeature, feature);
        });
      });
    }

    getLayer(): VectorLayer<any> {
      return new VectorLayer({
          source: this.source,
          renderBuffer: 1000,
      });
    }
}
