import { EventEmitter, Injectable, Output } from '@angular/core';

import { MapActions } from '@pgis/shared/models/map-actions.enum';
import { SelectedFeature } from '../selected-feature.model';

import Map from 'ol/Map';
import Select from 'ol/interaction/Select.js';
import Style, { StyleLike } from 'ol/style/Style';
import Fill from 'ol/style/Fill';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle';
import Feature from 'ol/Feature';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';

import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  @Output()
  selectionChanged: EventEmitter<Feature> = new EventEmitter<Feature>();

  @Output()
  multiSelectionChanged: EventEmitter<SelectedFeature[]> = new EventEmitter<SelectedFeature[]>();

  @Output()
  objectSelected: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  objectDeselected: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  multiObjectsDeselected: EventEmitter<SelectedFeature[]> = new EventEmitter<SelectedFeature[]>();

  @Output()
  multiSelectPolygonDrawn: EventEmitter<any> = new EventEmitter<any>();

  map: Map;
  selectObjectInteraction: Select;
  selectMultipleObjectsInteraction: Select;
  selectMultipleObjectsByPolygonInteraction: Draw;

  selectedFeatureDefaultStyle: StyleLike;
  selectedFeature: Feature;

  selectedFeatures: SelectedFeature[] = [];

  visibleFeatures: Feature[] = [];

  selectByPolygonLayer: VectorLayer<VectorSource>;
  selectByPolygonSource: VectorSource;

  selectionStyle = new Style({
    fill: new Fill({
        color: '#ffffff40'
    }),
    stroke: new Stroke({
        color: '#000000',
        width: 5
    }),
    image: new CircleStyle({
        radius: 15,
        fill: new Fill({ color: '#666666' }),
        stroke: new Stroke({ color: '#bada55', width: 1 })
    })
  });

  constructor() { }

  enable(map: Map, selectType: MapActions, selectedLayerIds?: number[]): void {
    this.map = map;
    switch (selectType) {
      case 'SelectObject':
        if(!this.selectObjectInteraction) {
          this.initSelectObjectInteraction();
          this.map.addInteraction(this.selectObjectInteraction);
        }
      break;

      case 'SelectMultipleObjects':
        if(!this.selectMultipleObjectsInteraction) {
          this.initSelectMultipleObjectsInteraction(selectedLayerIds);
          this.map.addInteraction(this.selectMultipleObjectsInteraction);
        }
      break;

      case 'SelectMultipleObjectsByPolygon':
        if(!this.selectMultipleObjectsByPolygonInteraction) {
          this.initSelectMultipleObjectsByPolygonInteraction(selectedLayerIds);
          this.map.addInteraction(this.selectMultipleObjectsByPolygonInteraction);

          this.selectByPolygonSource = new VectorSource();
          this.selectByPolygonLayer = new VectorLayer({
            source: this.selectByPolygonSource
          });
          this.map.addLayer(this.selectByPolygonLayer);
        }
      break;
    }
  }

  disable(selectType: MapActions): void  {
    switch (selectType) {
      case 'SelectObject':
        if(this.selectObjectInteraction) {
          this.map.removeInteraction(this.selectObjectInteraction);
          this.selectObjectInteraction = null;
        }
      break;

      case 'SelectMultipleObjects':
        if(this.selectMultipleObjectsInteraction) {
          this.resetSelection();
          this.map.removeInteraction(this.selectMultipleObjectsInteraction);
          this.selectMultipleObjectsInteraction = null;
        }
      break;

      case 'SelectMultipleObjectsByPolygon':
        this.resetSelection();
      break;
    }
  }

  resetSelection() {
    if(this.selectedFeature) {
      let deselectedFeatureId = <number>this.selectedFeature.getId();
      this.objectDeselected.emit(deselectedFeatureId);
      this.selectionChanged.emit(null);
    }

    if(this.selectedFeatures && this.selectedFeatures.length > 0) {
      this.multiSelectionChanged.emit(null);
      this.multiObjectsDeselected.emit(this.selectedFeatures);
      this.selectedFeatures = [];
    }
  }

  private initSelectObjectInteraction() {
    this.selectObjectInteraction = new Select({ 
      style: null,
      hitTolerance: 4,
      layers: (layer) => {
        return layer.get('layerName') !== 'geolocation';
      }
    });

    this.selectObjectInteraction.on('select', (event) => {
      if(event.deselected[0]) {
        let deselectedFeatureId = <number>event.deselected[0].getId();
        this.objectDeselected.emit(deselectedFeatureId);
      }

      if(event.selected[0]) {
        this.selectedFeature = event.selected[0];
        this.selectedFeatureDefaultStyle = event.selected[0].getStyle();
        event.selected[0].setStyle(this.selectionStyle);
        this.objectSelected.emit();
      }
      
      this.selectionChanged.emit(event.selected[0]);
    });
  }

  private initSelectMultipleObjectsInteraction(selectedLayerIds: number[]) {
    this.selectMultipleObjectsInteraction = new Select({
      style: null,
      hitTolerance: 4,
      layers: (layer) => {
        return layer.get('layerName')!== 'geolocation';
      }
    });

    const handleEvent = this.selectMultipleObjectsInteraction.handleEvent;

    this.selectMultipleObjectsInteraction.handleEvent = (event) => {
      const eventType = event.type;
      const f = handleEvent.bind(this.selectMultipleObjectsInteraction);
      const result = f(event);

      if(eventType !== 'singleclick') {
        return result;
      }
      
      this.map.forEachFeatureAtPixel(event.pixel, (feature: Feature) => {
        if(!selectedLayerIds.includes(feature.getProperties().classId)) {
          return;
        }

        if(!this.selectedFeatures.find(f => f.feature.getId() == feature.getId())) {
          const defaultStyle = _.clone(feature.getStyle());
          this.selectedFeatures.push({feature: feature, style: defaultStyle});
          feature.setStyle(this.selectionStyle);
          this.multiSelectionChanged.emit(this.selectedFeatures);
        } else {
          const deselectedFeature = this.selectedFeatures.find(f => f.feature.getId() == feature.getId());
          const deselectedFeatureIndex = this.selectedFeatures.indexOf(deselectedFeature);
          if(deselectedFeatureIndex > -1) {
            this.selectedFeatures.splice(deselectedFeatureIndex, 1);
            this.multiSelectionChanged.emit(this.selectedFeatures);
          }
          feature.setStyle(deselectedFeature.style);
        }
      });

      return result;
    };
  }

  private initSelectMultipleObjectsByPolygonInteraction(selectedLayerIds: number[]) {
    this.selectMultipleObjectsByPolygonInteraction = new Draw({
      type: 'Polygon',
      source: this.selectByPolygonSource
    });

    this.selectMultipleObjectsByPolygonInteraction.on('drawend', (event) => {
      this.map.removeInteraction(this.selectMultipleObjectsByPolygonInteraction);
      this.selectMultipleObjectsByPolygonInteraction = null;
      this.map.removeLayer(this.selectByPolygonLayer);
      this.selectByPolygonLayer = null;
      this.selectByPolygonSource = null;

      this.multiSelectPolygonDrawn.emit();

      const drawnGeom = event.feature.getGeometry();

      this.visibleFeatures.forEach(feature => {
        if (!selectedLayerIds.includes(feature.getProperties().classId)
            || !drawnGeom.intersectsExtent(feature.getGeometry().getExtent())) {
            return;
        }

        const defaultStyle = _.clone(feature.getStyle());
        this.selectedFeatures.push({feature: feature, style: defaultStyle});
        feature.setStyle(this.selectionStyle);
        this.multiSelectionChanged.emit(this.selectedFeatures);
        this.objectSelected.emit();
      });
      
    });
  }
}
