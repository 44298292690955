import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileFilesService extends BaseService<any> {

  constructor(httpClient: HttpClient) { 
    super(httpClient, '/api/v1/profile-files');
  }

  async getCustomIcon(companyId: number, iconName: string): Promise<Blob> {
    return await lastValueFrom(this.httpClient.get<any>(`${this.baseApiUrl}/custom-icons/${companyId}/${iconName}`, { responseType: 'blob' as 'json' }));
  }
}
