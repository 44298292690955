import { AfterViewInit, Component } from '@angular/core';
import { TaskImage } from '@pgis/shared/models/task-image.model';

import { Task } from '@pgis/shared/models/task.model';
import * as moment from 'moment';

@Component({
  selector: 'app-task-status-history-modal',
  templateUrl: './task-status-history-modal.component.html',
  styleUrls: ['./task-status-history-modal.component.less']
})
export class TaskStatusHistoryModalComponent implements AfterViewInit {

  task: Task;
  imageArray: TaskImage[] = [];

  ngAfterViewInit(): void {
    // workaround for not removable vertical scrollbar in table header
    const tableHeaderElement = Array.from(document.getElementsByClassName('ant-table-header nz-table-hide-scrollbar') as HTMLCollectionOf<HTMLElement>)[0];
    tableHeaderElement.style.cssText = 'overflow: hidden';
  }

  formatDate(date: string): string{
    return moment(new Date(date)).format('DD.MM.yyyy HH:mm:ss');
  }
}
