<div *ngFor="let file of taskFiles" 
    class="alert alert-secondary">
    <div nz-row>
        <div nz-col nzSpan="16"><span>{{ file.name }}</span></div>
        <div nz-col nzSpan="1"></div>
        <div nz-col nzSpan="7"><span>{{ formatDate(file.added) }}</span></div>
    </div>
    <nz-divider></nz-divider>
    <div nz-row>
        <div nz-col nzSpan="4">
            <button nz-button 
                    nz-popover
                    class="primary-btn"
                    nzType="primary" 
                    [nzSize]="'large'"
                    nzPopoverContent="Lejupielādēt"
                    (click)="downloadFile(file.id, file.taskId)">
                <fa-icon [icon]="['fas', 'download']"></fa-icon></button>
        </div>
    </div>
</div>
